import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Post from '../components/Post';
import Sidebar from '../components/Sidebar';

require('prismjs/themes/prism-okaidia.css');

export default function (props) {
  const [currentPage, setPage] = useState(0);
  const { title, subtitle } = props.data.site.siteMetadata;
  const items = props.data.allMarkdownRemark.edges.map(p => (<Post
    data={p}
    key={p.node.fields.slug}
  />));

  const itemsPerPage = 10;
  const startItem = currentPage * itemsPerPage;
  const endItem = (currentPage + 1) * itemsPerPage;
  const numberOfPages = Math.ceil(items.length / itemsPerPage);

  return (
    <Layout>
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={subtitle} />
        </Helmet>
        <Sidebar {...props} />
        <div className="content">
          <div className="content__inner">{items.slice(startItem, endItem)}</div>
          <button onClick={() => setPage(currentPage - 1)} disabled={currentPage === 0}>Previous
          </button>
          <button
            onClick={() => setPage(currentPage + 1)}
            disabled={currentPage === numberOfPages - 1}
          >Next
          </button>
        </div>
      </div>
    </Layout>
  );
}


export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          email
          twitter
          github
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            date
            category
            description
          }
        }
      }
    }
  }
`;
